@media (prefers-color-scheme: dark) {
  .wmde-markdown, .wmde-markdown-var {
    color-scheme: dark;
    --color-prettylights-syntax-comment: #8b949e;
    --color-prettylights-syntax-constant: #79c0ff;
    --color-prettylights-syntax-entity: #d2a8ff;
    --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
    --color-prettylights-syntax-entity-tag: #7ee787;
    --color-prettylights-syntax-keyword: #ff7b72;
    --color-prettylights-syntax-string: #a5d6ff;
    --color-prettylights-syntax-variable: #ffa657;
    --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-carriage-return-bg: #b62324;
    --color-prettylights-syntax-string-regexp: #7ee787;
    --color-prettylights-syntax-markup-list: #f2cc60;
    --color-prettylights-syntax-markup-heading: #1f6feb;
    --color-prettylights-syntax-markup-italic: #c9d1d9;
    --color-prettylights-syntax-markup-bold: #c9d1d9;
    --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
    --color-prettylights-syntax-markup-deleted-bg: #67060c;
    --color-prettylights-syntax-markup-inserted-text: #aff5b4;
    --color-prettylights-syntax-markup-inserted-bg: #033a16;
    --color-prettylights-syntax-markup-changed-text: #ffdfb6;
    --color-prettylights-syntax-markup-changed-bg: #5a1e02;
    --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
    --color-prettylights-syntax-markup-ignored-bg: #1158c7;
    --color-prettylights-syntax-meta-diff-range: #d2a8ff;
    --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
    --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
    --color-fg-default: #c9d1d9;
    --color-fg-muted: #8b949e;
    --color-fg-subtle: #484f58;
    --color-canvas-default: #0d1117;
    --color-canvas-subtle: #161b22;
    --color-border-default: #30363d;
    --color-border-muted: #21262d;
    --color-neutral-muted: #6e768166;
    --color-accent-fg: #58a6ff;
    --color-accent-emphasis: #1f6feb;
    --color-attention-subtle: #bb800926;
    --color-danger-fg: #f85149;
  }
}

@media (prefers-color-scheme: light) {
  .wmde-markdown, .wmde-markdown-var {
    color-scheme: light;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-fg-default: #24292f;
    --color-fg-muted: #57606a;
    --color-fg-subtle: #6e7781;
    --color-canvas-default: #fff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: #d8dee4;
    --color-neutral-muted: #afb8c133;
    --color-accent-fg: #0969da;
    --color-accent-emphasis: #0969da;
    --color-attention-subtle: #fff8c5;
    --color-danger-fg: #cf222e;
  }
}

[data-color-mode*="dark"] .wmde-markdown, [data-color-mode*="dark"] .wmde-markdown-var, body[data-color-mode*="dark"] {
  color-scheme: dark;
  --color-prettylights-syntax-comment: #8b949e;
  --color-prettylights-syntax-constant: #79c0ff;
  --color-prettylights-syntax-entity: #d2a8ff;
  --color-prettylights-syntax-storage-modifier-import: #c9d1d9;
  --color-prettylights-syntax-entity-tag: #7ee787;
  --color-prettylights-syntax-keyword: #ff7b72;
  --color-prettylights-syntax-string: #a5d6ff;
  --color-prettylights-syntax-variable: #ffa657;
  --color-prettylights-syntax-brackethighlighter-unmatched: #f85149;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-invalid-illegal-bg: #8e1519;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-carriage-return-bg: #b62324;
  --color-prettylights-syntax-string-regexp: #7ee787;
  --color-prettylights-syntax-markup-list: #f2cc60;
  --color-prettylights-syntax-markup-heading: #1f6feb;
  --color-prettylights-syntax-markup-italic: #c9d1d9;
  --color-prettylights-syntax-markup-bold: #c9d1d9;
  --color-prettylights-syntax-markup-deleted-text: #ffdcd7;
  --color-prettylights-syntax-markup-deleted-bg: #67060c;
  --color-prettylights-syntax-markup-inserted-text: #aff5b4;
  --color-prettylights-syntax-markup-inserted-bg: #033a16;
  --color-prettylights-syntax-markup-changed-text: #ffdfb6;
  --color-prettylights-syntax-markup-changed-bg: #5a1e02;
  --color-prettylights-syntax-markup-ignored-text: #c9d1d9;
  --color-prettylights-syntax-markup-ignored-bg: #1158c7;
  --color-prettylights-syntax-meta-diff-range: #d2a8ff;
  --color-prettylights-syntax-brackethighlighter-angle: #8b949e;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #484f58;
  --color-prettylights-syntax-constant-other-reference-link: #a5d6ff;
  --color-fg-default: #c9d1d9;
  --color-fg-muted: #8b949e;
  --color-fg-subtle: #484f58;
  --color-canvas-default: #0d1117;
  --color-canvas-subtle: #161b22;
  --color-border-default: #30363d;
  --color-border-muted: #21262d;
  --color-neutral-muted: #6e768166;
  --color-accent-fg: #58a6ff;
  --color-accent-emphasis: #1f6feb;
  --color-attention-subtle: #bb800926;
  --color-danger-fg: #f85149;
}

[data-color-mode*="light"] .wmde-markdown, [data-color-mode*="light"] .wmde-markdown-var, body[data-color-mode*="light"] {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #fff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: #d8dee4;
  --color-neutral-muted: #afb8c133;
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}

.wmde-markdown {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 16px;
  line-height: 1.5;
}

.wmde-markdown details, .wmde-markdown figcaption, .wmde-markdown figure {
  display: block;
}

.wmde-markdown summary {
  display: list-item;
}

.wmde-markdown [hidden] {
  display: none !important;
}

.wmde-markdown a {
  color: var(--color-accent-fg);
  background-color: #0000;
  text-decoration: none;
}

.wmde-markdown a:active, .wmde-markdown a:hover {
  outline-width: 0;
}

.wmde-markdown abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

.wmde-markdown b, .wmde-markdown strong {
  font-weight: 600;
}

.wmde-markdown dfn {
  font-style: italic;
}

.wmde-markdown h1 {
  border-bottom: 1px solid var(--color-border-muted);
  margin: .67em 0;
  padding-bottom: .3em;
  font-size: 2em;
  font-weight: 600;
}

.wmde-markdown mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-text-primary);
}

.wmde-markdown small {
  font-size: 90%;
}

.wmde-markdown sub, .wmde-markdown sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.wmde-markdown sub {
  bottom: -.25em;
}

.wmde-markdown sup {
  top: -.5em;
}

.wmde-markdown img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
  border-style: none;
}

.wmde-markdown code, .wmde-markdown kbd, .wmde-markdown pre, .wmde-markdown samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

.wmde-markdown figure {
  margin: 1em 40px;
}

.wmde-markdown hr {
  box-sizing: content-box;
  border-bottom: 1px solid var(--color-border-muted);
  height: .25em;
  background: none;
  background-color: var(--color-border-default);
  border: 0;
  margin: 24px 0;
  padding: 0;
  overflow: hidden;
}

.wmde-markdown input {
  font: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible;
}

.wmde-markdown [type="button"], .wmde-markdown [type="reset"], .wmde-markdown [type="submit"] {
  -webkit-appearance: button;
}

.wmde-markdown [type="button"]::-moz-focus-inner, .wmde-markdown [type="reset"]::-moz-focus-inner, .wmde-markdown [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.wmde-markdown [type="button"]:-moz-focusring, .wmde-markdown [type="reset"]:-moz-focusring, .wmde-markdown [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.wmde-markdown [type="checkbox"], .wmde-markdown [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.wmde-markdown [type="number"]::-webkit-inner-spin-button, .wmde-markdown [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.wmde-markdown [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.wmde-markdown [type="search"]::-webkit-search-cancel-button, .wmde-markdown [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.wmde-markdown ::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

.wmde-markdown ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.wmde-markdown a:hover {
  text-decoration: underline;
}

.wmde-markdown hr:before {
  content: "";
  display: table;
}

.wmde-markdown hr:after {
  clear: both;
  content: "";
  display: table;
}

.wmde-markdown table {
  border-spacing: 0;
  border-collapse: collapse;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  display: block;
  overflow: auto;
}

.wmde-markdown td, .wmde-markdown th {
  padding: 0;
}

.wmde-markdown details summary {
  cursor: pointer;
}

.wmde-markdown details:not([open]) > :not(summary) {
  display: none !important;
}

.wmde-markdown kbd {
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
  border-radius: 6px;
  padding: 3px 5px;
  font: 11px / 10px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  display: inline-block;
}

.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.wmde-markdown table {
  border-spacing: 0;
  border-collapse: collapse;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
  display: block;
  overflow: auto;
}

.wmde-markdown td, .wmde-markdown th {
  padding: 0;
}

.wmde-markdown details summary {
  cursor: pointer;
}

.wmde-markdown details:not([open]) > :not(summary) {
  display: none !important;
}

.wmde-markdown kbd {
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
  border-radius: 6px;
  padding: 3px 5px;
  font: 11px / 10px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  display: inline-block;
}

.wmde-markdown h1, .wmde-markdown h2, .wmde-markdown h3, .wmde-markdown h4, .wmde-markdown h5, .wmde-markdown h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.wmde-markdown h2 {
  border-bottom: 1px solid var(--color-border-muted);
  padding-bottom: .3em;
  font-size: 1.5em;
  font-weight: 600;
}

.wmde-markdown h3 {
  font-size: 1.25em;
  font-weight: 600;
}

.wmde-markdown h4 {
  font-size: 1em;
  font-weight: 600;
}

.wmde-markdown h5 {
  font-size: .875em;
  font-weight: 600;
}

.wmde-markdown h6 {
  color: var(--color-fg-muted);
  font-size: .85em;
  font-weight: 600;
}

.wmde-markdown p {
  margin-top: 0;
  margin-bottom: 10px;
}

.wmde-markdown blockquote {
  color: var(--color-fg-muted);
  border-left: .25em solid var(--color-border-default);
  margin: 0;
  padding: 0 1em;
}

.wmde-markdown ul, .wmde-markdown ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.wmde-markdown ol ol, .wmde-markdown ul ol {
  list-style-type: lower-roman;
}

.wmde-markdown ul ul ol, .wmde-markdown ul ol ol, .wmde-markdown ol ul ol, .wmde-markdown ol ol ol {
  list-style-type: lower-alpha;
}

.wmde-markdown dd {
  margin-left: 0;
}

.wmde-markdown tt, .wmde-markdown code {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}

.wmde-markdown pre {
  word-wrap: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}

.wmde-markdown .octicon {
  vertical-align: text-bottom;
  fill: currentColor;
  display: inline-block;
  overflow: visible !important;
}

.wmde-markdown ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}

.wmde-markdown input::-webkit-outer-spin-button, .wmde-markdown input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.wmde-markdown [data-catalyst] {
  display: block;
}

.wmde-markdown:before {
  content: "";
  display: table;
}

.wmde-markdown:after {
  clear: both;
  content: "";
  display: table;
}

.wmde-markdown > :first-child {
  margin-top: 0 !important;
}

.wmde-markdown > :last-child {
  margin-bottom: 0 !important;
}

.wmde-markdown a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.wmde-markdown .absent {
  color: var(--color-danger-fg);
}

.wmde-markdown a.anchor {
  float: left;
  margin-left: -20px;
  padding-right: 4px;
  line-height: 1;
}

.wmde-markdown .anchor:focus {
  outline: none;
}

.wmde-markdown p, .wmde-markdown blockquote, .wmde-markdown ul, .wmde-markdown ol, .wmde-markdown dl, .wmde-markdown table, .wmde-markdown pre, .wmde-markdown details {
  margin-top: 0;
  margin-bottom: 16px;
}

.wmde-markdown blockquote > :first-child {
  margin-top: 0;
}

.wmde-markdown blockquote > :last-child {
  margin-bottom: 0;
}

.wmde-markdown sup > a:before {
  content: "[";
}

.wmde-markdown sup > a:after {
  content: "]";
}

.wmde-markdown h1 .octicon-link, .wmde-markdown h2 .octicon-link, .wmde-markdown h3 .octicon-link, .wmde-markdown h4 .octicon-link, .wmde-markdown h5 .octicon-link, .wmde-markdown h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}

.wmde-markdown h1:hover .anchor, .wmde-markdown h2:hover .anchor, .wmde-markdown h3:hover .anchor, .wmde-markdown h4:hover .anchor, .wmde-markdown h5:hover .anchor, .wmde-markdown h6:hover .anchor {
  text-decoration: none;
}

.wmde-markdown h1:hover .anchor .octicon-link, .wmde-markdown h2:hover .anchor .octicon-link, .wmde-markdown h3:hover .anchor .octicon-link, .wmde-markdown h4:hover .anchor .octicon-link, .wmde-markdown h5:hover .anchor .octicon-link, .wmde-markdown h6:hover .anchor .octicon-link {
  visibility: visible;
}

.wmde-markdown h1 tt, .wmde-markdown h1 code, .wmde-markdown h2 tt, .wmde-markdown h2 code, .wmde-markdown h3 tt, .wmde-markdown h3 code, .wmde-markdown h4 tt, .wmde-markdown h4 code, .wmde-markdown h5 tt, .wmde-markdown h5 code, .wmde-markdown h6 tt, .wmde-markdown h6 code {
  font-size: inherit;
  padding: 0 .2em;
}

.wmde-markdown ul.no-list, .wmde-markdown ol.no-list {
  padding: 0;
  list-style-type: none;
}

.wmde-markdown ol[type="1"] {
  list-style-type: decimal;
}

.wmde-markdown ol[type="a"] {
  list-style-type: lower-alpha;
}

.wmde-markdown ol[type="i"] {
  list-style-type: lower-roman;
}

.wmde-markdown div > ol:not([type]) {
  list-style-type: decimal;
}

.wmde-markdown ul ul, .wmde-markdown ul ol, .wmde-markdown ol ol, .wmde-markdown ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.wmde-markdown li > p {
  margin-top: 16px;
}

.wmde-markdown li + li {
  margin-top: .25em;
}

.wmde-markdown dl {
  padding: 0;
}

.wmde-markdown dl dt {
  margin-top: 16px;
  padding: 0;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.wmde-markdown dl dd {
  margin-bottom: 16px;
  padding: 0 16px;
}

.wmde-markdown table th {
  font-weight: 600;
}

.wmde-markdown table th, .wmde-markdown table td {
  border: 1px solid var(--color-border-default);
  padding: 6px 13px;
}

.wmde-markdown table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}

.wmde-markdown table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}

.wmde-markdown table img {
  background-color: #0000;
}

.wmde-markdown img[align="right"] {
  padding-left: 20px;
}

.wmde-markdown img[align="left"] {
  padding-right: 20px;
}

.wmde-markdown .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: #0000;
}

.wmde-markdown span.frame {
  display: block;
  overflow: hidden;
}

.wmde-markdown span.frame > span {
  float: left;
  width: auto;
  border: 1px solid var(--color-border-default);
  margin: 13px 0 0;
  padding: 7px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.frame span img {
  float: left;
  display: block;
}

.wmde-markdown span.frame span span {
  clear: both;
  color: var(--color-fg-default);
  padding: 5px 0 0;
  display: block;
}

.wmde-markdown span.align-center {
  clear: both;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-center > span {
  text-align: center;
  margin: 13px auto 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-center span img {
  text-align: center;
  margin: 0 auto;
}

.wmde-markdown span.align-right {
  clear: both;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-right > span {
  text-align: right;
  margin: 13px 0 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.align-right span img {
  text-align: right;
  margin: 0;
}

.wmde-markdown span.float-left {
  float: left;
  margin-right: 13px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.float-left span {
  margin: 13px 0 0;
}

.wmde-markdown span.float-right {
  float: right;
  margin-left: 13px;
  display: block;
  overflow: hidden;
}

.wmde-markdown span.float-right > span {
  text-align: right;
  margin: 13px auto 0;
  display: block;
  overflow: hidden;
}

.wmde-markdown code, .wmde-markdown tt {
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
  margin: 0;
  padding: .2em .4em;
  font-size: 85%;
}

.wmde-markdown code br, .wmde-markdown tt br {
  display: none;
}

.wmde-markdown del code {
  text-decoration: inherit;
}

.wmde-markdown pre code {
  font-size: 100%;
}

.wmde-markdown pre > code {
  word-break: normal;
  white-space: pre;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.wmde-markdown pre {
  background-color: var(--color-canvas-subtle);
  border-radius: 6px;
  font-size: 85%;
  line-height: 1.45;
}

.wmde-markdown pre code, .wmde-markdown pre tt {
  max-width: auto;
  line-height: inherit;
  word-wrap: normal;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  display: inline;
  overflow: visible;
}

.wmde-markdown pre > code {
  padding: 16px;
  display: block;
  overflow: auto;
}

.wmde-markdown .csv-data td, .wmde-markdown .csv-data th {
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  font-size: 12px;
  line-height: 1;
  overflow: hidden;
}

.wmde-markdown .csv-data .blob-num {
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
  padding: 10px 8px 9px;
}

.wmde-markdown .csv-data tr {
  border-top: 0;
}

.wmde-markdown .csv-data th {
  background: var(--color-canvas-subtle);
  border-top: 0;
  font-weight: 600;
}

.wmde-markdown .footnotes {
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
  font-size: 12px;
}

.wmde-markdown .footnotes ol {
  padding-left: 16px;
}

.wmde-markdown .footnotes li {
  position: relative;
}

.wmde-markdown .footnotes li:target:before {
  pointer-events: none;
  content: "";
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
  position: absolute;
  inset: -8px -8px -8px -24px;
}

.wmde-markdown .footnotes li:target {
  color: var(--color-fg-default);
}

.wmde-markdown .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}

.wmde-markdown .task-list-item {
  list-style-type: none;
}

.wmde-markdown .task-list-item label {
  font-weight: 400;
}

.wmde-markdown .task-list-item.enabled label {
  cursor: pointer;
}

.wmde-markdown .task-list-item + .wmde-markdown .task-list-item {
  margin-top: 3px;
}

.wmde-markdown .task-list-item .handle {
  display: none;
}

.wmde-markdown .task-list-item-checkbox, .wmde-markdown input[type="checkbox"] {
  vertical-align: middle;
  margin: 0 .2em .25em -1.6em;
}

.wmde-markdown .contains-task-list:dir(rtl) .task-list-item-checkbox, .wmde-markdown .contains-task-list:dir(rtl) input[type="checkbox"] {
  margin: 0 -1.6em .25em .2em;
}

.wmde-markdown ::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(50%);
  filter: invert(50%);
}

.wmde-markdown pre[class*="language-"] {
  position: relative;
}

.wmde-markdown pre .copied {
  visibility: hidden;
  cursor: pointer;
  color: var(--color-fg-defaul);
  background: var(--color-border-default);
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;
}

.wmde-markdown pre .copied .octicon-copy {
  display: block;
}

.wmde-markdown pre .copied .octicon-check {
  display: none;
}

.wmde-markdown pre:hover .copied {
  visibility: visible;
}

.wmde-markdown pre:hover .copied:hover {
  background: var(--color-prettylights-syntax-entity-tag);
  color: var(--color-canvas-default);
}

.wmde-markdown pre:hover .copied:active, .wmde-markdown pre .copied.active {
  color: var(--color-canvas-default);
  background: #2e9b33;
}

.wmde-markdown pre .active .octicon-copy {
  display: none;
}

.wmde-markdown pre .active .octicon-check {
  display: block;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: var(--color-prettylights-syntax-comment);
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.selector, .token.constant, .token.symbol, .token.deleted {
  color: var(--color-prettylights-syntax-entity-tag);
}

.token.maybe-class-name {
  color: var(--color-prettylights-syntax-variable);
}

.token.property-access, .token.operator, .token.boolean, .token.number, .token.selector .token.class, .token.attr-name, .token.string, .token.char, .token.builtin {
  color: var(--color-prettylights-syntax-constant);
}

.token.deleted {
  color: var(--color-prettylights-syntax-markup-deleted-text);
}

.code-line .token.deleted {
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}

.token.inserted {
  color: var(--color-prettylights-syntax-markup-inserted-text);
}

.code-line .token.inserted {
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}

.token.variable {
  color: var(--color-prettylights-syntax-constant);
}

.token.entity, .token.url, .language-css .token.string, .style .token.string, .token.color, .token.atrule, .token.attr-value, .token.function, .token.class-name {
  color: var(--color-prettylights-syntax-string);
}

.token.rule, .token.regex, .token.important, .token.keyword {
  color: var(--color-prettylights-syntax-keyword);
}

.token.coord {
  color: var(--color-prettylights-syntax-meta-diff-range);
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.w-md-editor-aree {
  border-radius: 5px;
  overflow: auto;
}

.w-md-editor-text {
  min-height: 100%;
  text-align: left;
  white-space: pre-wrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  box-sizing: border-box;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  position: relative;
}

.w-md-editor-text-pre, .w-md-editor-text-input, .w-md-editor-text > .w-md-editor-text-pre {
  box-sizing: inherit;
  display: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  -webkit-font-variant-ligatures: inherit;
  font-variant-ligatures: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  tab-size: inherit;
  text-indent: inherit;
  text-rendering: inherit;
  text-transform: inherit;
  white-space: inherit;
  overflow-wrap: inherit;
  word-break: inherit;
  word-break: normal;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.w-md-editor-text-pre > code, .w-md-editor-text-input > code, .w-md-editor-text > .w-md-editor-text-pre > code {
  font-family: inherit;
}

.w-md-editor-text-pre {
  pointer-events: none;
  margin: 0;
  position: relative;
}

.w-md-editor-text-input {
  height: 100%;
  width: 100%;
  resize: none;
  color: inherit;
  padding: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-text-fill-color: transparent;
  outline: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.w-md-editor-text-input:empty {
  -webkit-text-fill-color: inherit !important;
}

.w-md-editor-text-pre, .w-md-editor-text-input {
  word-wrap: pre;
  word-break: break-word;
  white-space: pre-wrap;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-md-editor-text-input {
    color: #0000 !important;
  }

  .w-md-editor-text-input::selection {
    color: #0000 !important;
    background-color: #accef7 !important;
  }
}

.w-md-editor-text-pre .punctuation {
  color: var(--color-prettylights-syntax-comment) !important;
}

.w-md-editor-text-pre .token.url, .w-md-editor-text-pre .token.content {
  color: var(--color-prettylights-syntax-constant) !important;
}

.w-md-editor-text-pre .token.title.important {
  color: var(--color-prettylights-syntax-markup-bold);
}

.w-md-editor-text-pre .token.code-block .function {
  color: var(--color-prettylights-syntax-entity);
}

.w-md-editor-text-pre .token.bold {
  font-weight: unset !important;
}

.w-md-editor-text-pre .token.title {
  line-height: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
}

.w-md-editor-text-pre .token.code.keyword {
  color: var(--color-prettylights-syntax-constant) !important;
}

.w-md-editor-text-pre .token.strike, .w-md-editor-text-pre .token.strike .content {
  color: var(--color-prettylights-syntax-markup-deleted-text) !important;
}

.w-md-editor-toolbar-child {
  box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default), 0 1px 1px var(--color-border-default);
  background-color: var(--color-canvas-default);
  z-index: 1;
  border-radius: 3px;
  display: none;
  position: absolute;
}

.w-md-editor-toolbar-child.active {
  display: block;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar {
  border-bottom: 0;
  border-radius: 3px;
  padding: 3px;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li {
  display: block;
}

.w-md-editor-toolbar-child .w-md-editor-toolbar ul > li button {
  width: -webkit-fill-available;
  height: initial;
  box-sizing: border-box;
  margin: 0;
  padding: 3px 4px 2px;
}

.w-md-editor-toolbar {
  border-bottom: 1px solid var(--color-border-default);
  background-color: var(--color-canvas-default);
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  display: flex;
}

.w-md-editor-toolbar ul, .w-md-editor-toolbar li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.w-md-editor-toolbar li {
  font-size: 14px;
  display: inline-block;
}

.w-md-editor-toolbar li > button {
  height: 20px;
  text-transform: none;
  cursor: pointer;
  white-space: nowrap;
  color: var(--color-fg-default);
  background: none;
  border: none;
  border-radius: 2px;
  outline: none;
  margin: 0 1px;
  padding: 4px;
  font-weight: normal;
  line-height: 14px;
  transition: all .3s;
  overflow: visible;
}

.w-md-editor-toolbar li > button:hover, .w-md-editor-toolbar li > button:focus {
  background-color: var(--color-neutral-muted);
  color: var(--color-accent-fg);
}

.w-md-editor-toolbar li > button:active {
  background-color: var(--color-neutral-muted);
  color: var(--color-danger-fg);
}

.w-md-editor-toolbar li > button:disabled {
  color: var(--color-border-default);
  cursor: not-allowed;
}

.w-md-editor-toolbar li > button:disabled:hover {
  color: var(--color-border-default);
  background-color: #0000;
}

.w-md-editor-toolbar li.active > button {
  color: var(--color-accent-fg);
  background-color: var(--color-neutral-muted);
}

.w-md-editor-toolbar-divider {
  height: 14px;
  width: 1px;
  vertical-align: middle;
  background-color: var(--color-border-default);
  margin: -3px 3px 0 !important;
}

.w-md-editor-bar {
  cursor: s-resize;
  width: 14px;
  z-index: 3;
  height: 10px;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 0 3px;
  margin-top: -11px;
  margin-right: 0;
  position: absolute;
  right: 0;
}

.w-md-editor-bar svg {
  margin: 0 auto;
  display: block;
}

.w-md-editor {
  text-align: left;
  color: var(--color-fg-default);
  box-shadow: 0 0 0 1px var(--color-border-default), 0 0 0 var(--color-border-default), 0 1px 1px var(--color-border-default);
  background-color: var(--color-canvas-default);
  border-radius: 3px;
  padding-bottom: 1px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  position: relative;
}

.w-md-editor .copied {
  display: none !important;
}

.w-md-editor-content {
  height: calc(100% - 39.1px);
  border-radius: 0 0 3px;
  position: relative;
}

.w-md-editor-input {
  width: 50%;
  height: 100%;
}

.w-md-editor-preview {
  width: 50%;
  box-sizing: border-box;
  box-shadow: inset 1px 0 0 0 var(--color-border-default);
  padding: 10px 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.w-md-editor-preview .anchor {
  display: none;
}

.w-md-editor-preview .contains-task-list {
  list-style: none;
}

.w-md-editor-show-preview .w-md-editor-input {
  width: 0%;
  background-color: var(--color-canvas-default);
  overflow: hidden;
}

.w-md-editor-show-preview .w-md-editor-preview {
  width: 100%;
  box-shadow: inset 0 0;
}

.w-md-editor-show-edit .w-md-editor-input {
  width: 100%;
}

.w-md-editor-show-edit .w-md-editor-preview {
  width: 0%;
  padding: 0;
}

.w-md-editor-fullscreen {
  z-index: 99999;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.w-md-editor-fullscreen .w-md-editor-content {
  height: 100%;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Rubik Light ), local(Rubik-Light), url("rubik-latin-300.718d1708.woff2") format("woff2"), url("rubik-latin-300.a0263a11.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Rubik Light italic), local(Rubik-Lightitalic), url("rubik-latin-300italic.07389d63.woff2") format("woff2"), url("rubik-latin-300italic.db4fed87.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Rubik Regular ), local(Rubik-Regular), url("rubik-latin-400.daf98782.woff2") format("woff2"), url("rubik-latin-400.ae323e8c.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Rubik Regular italic), local(Rubik-Regularitalic), url("rubik-latin-400italic.feed1f77.woff2") format("woff2"), url("rubik-latin-400italic.9dd662d8.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Rubik Medium ), local(Rubik-Medium), url("rubik-latin-500.faaeeb24.woff2") format("woff2"), url("rubik-latin-500.d19415b1.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local(Rubik Medium italic), local(Rubik-Mediumitalic), url("rubik-latin-500italic.ea74b3f8.woff2") format("woff2"), url("rubik-latin-500italic.165d903c.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(Rubik SemiBold ), local(Rubik-SemiBold), url("rubik-latin-600.685055ee.woff2") format("woff2"), url("rubik-latin-600.cb78eece.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local(Rubik SemiBold italic), local(Rubik-SemiBolditalic), url("rubik-latin-600italic.47e75ff9.woff2") format("woff2"), url("rubik-latin-600italic.4f6709f3.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Rubik Bold ), local(Rubik-Bold), url("rubik-latin-700.7d56c0c5.woff2") format("woff2"), url("rubik-latin-700.48313d67.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Rubik Bold italic), local(Rubik-Bolditalic), url("rubik-latin-700italic.e789b7cf.woff2") format("woff2"), url("rubik-latin-700italic.e4c3c44a.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local(Rubik ExtraBold ), local(Rubik-ExtraBold), url("rubik-latin-800.175af2ff.woff2") format("woff2"), url("rubik-latin-800.8d137da1.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local(Rubik ExtraBold italic), local(Rubik-ExtraBolditalic), url("rubik-latin-800italic.fa043ee9.woff2") format("woff2"), url("rubik-latin-800italic.bd76a5ff.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Rubik Black ), local(Rubik-Black), url("rubik-latin-900.f3e5a4db.woff2") format("woff2"), url("rubik-latin-900.768f338f.woff") format("woff");
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Rubik Black italic), local(Rubik-Blackitalic), url("rubik-latin-900italic.eaa66749.woff2") format("woff2"), url("rubik-latin-900italic.5322c6b6.woff") format("woff");
}

@font-face {
  font-family: Space Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Space Mono Regular ), local(Space Mono-Regular), url("space-mono-latin-400.562a54b4.woff2") format("woff2"), url("space-mono-latin-400.990a0597.woff") format("woff");
}

@font-face {
  font-family: Space Mono;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Space Mono Regular italic), local(Space Mono-Regularitalic), url("space-mono-latin-400italic.3e1ff9e5.woff2") format("woff2"), url("space-mono-latin-400italic.b5b51a7d.woff") format("woff");
}

@font-face {
  font-family: Space Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Space Mono Bold ), local(Space Mono-Bold), url("space-mono-latin-700.bcf8724d.woff2") format("woff2"), url("space-mono-latin-700.09b45128.woff") format("woff");
}

@font-face {
  font-family: Space Mono;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Space Mono Bold italic), local(Space Mono-Bolditalic), url("space-mono-latin-700italic.ad705981.woff2") format("woff2"), url("space-mono-latin-700italic.ddb1ba0b.woff") format("woff");
}

@font-face {
  font-family: Circular Std Medium;
  font-style: normal;
  font-weight: normal;
  src: local(Circular Std Medium), url("CircularStd-Medium.8e651e2f.woff") format("woff");
}

@font-face {
  font-family: Circular Std Bold;
  font-style: normal;
  font-weight: normal;
  src: local(Circular Std Bold), url("CircularStd-Bold.a2cea4db.woff") format("woff");
}

/*# sourceMappingURL=index.609597c9.css.map */
