/* rubik-300normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Rubik Light '),
    local('Rubik-Light'),
    url('./files/rubik-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* rubik-300italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Rubik Light italic'),
    local('Rubik-Lightitalic'),
    url('./files/rubik-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-400normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rubik Regular '),
    local('Rubik-Regular'),
    url('./files/rubik-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* rubik-400italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rubik Regular italic'),
    local('Rubik-Regularitalic'),
    url('./files/rubik-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-500normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Rubik Medium '),
    local('Rubik-Medium'),
    url('./files/rubik-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* rubik-500italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Rubik Medium italic'),
    local('Rubik-Mediumitalic'),
    url('./files/rubik-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-600normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Rubik SemiBold '),
    local('Rubik-SemiBold'),
    url('./files/rubik-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* rubik-600italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Rubik SemiBold italic'),
    local('Rubik-SemiBolditalic'),
    url('./files/rubik-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-700normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rubik Bold '),
    local('Rubik-Bold'),
    url('./files/rubik-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* rubik-700italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rubik Bold italic'),
    local('Rubik-Bolditalic'),
    url('./files/rubik-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-800normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Rubik ExtraBold '),
    local('Rubik-ExtraBold'),
    url('./files/rubik-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* rubik-800italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Rubik ExtraBold italic'),
    local('Rubik-ExtraBolditalic'),
    url('./files/rubik-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* rubik-900normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Rubik Black '),
    local('Rubik-Black'),
    url('./files/rubik-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* rubik-900italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Rubik Black italic'),
    local('Rubik-Blackitalic'),
    url('./files/rubik-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/rubik-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

