/* space-mono-400normal - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Space Mono Regular '),
    local('Space Mono-Regular'),
    url('./files/space-mono-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/space-mono-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* space-mono-400italic - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Space Mono Regular italic'),
    local('Space Mono-Regularitalic'),
    url('./files/space-mono-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/space-mono-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* space-mono-700normal - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Space Mono Bold '),
    local('Space Mono-Bold'),
    url('./files/space-mono-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/space-mono-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* space-mono-700italic - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Space Mono Bold italic'),
    local('Space Mono-Bolditalic'),
    url('./files/space-mono-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/space-mono-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

