@font-face {
  font-family: 'Circular Std Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Circular Std Medium'), url('./assets/CircularStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Circular Std Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Circular Std Bold'), url('./assets/CircularStd-Bold.woff') format('woff');
}