.w-md-editor-bar {
  position: absolute;
  cursor: s-resize;
  right: 0;
  margin-top: -11px;
  margin-right: 0;
  width: 14px;
  z-index: 3;
  height: 10px;
  border-radius: 0 0 3px 0;
  -webkit-user-select: none;
          user-select: none;
}
.w-md-editor-bar svg {
  display: block;
  margin: 0 auto;
}
